<template>
  <v-main style="padding: 0%">
    <v-card height="100vh">
      <v-card-title style="padding: 0%">
        <v-toolbar style="padding-left: 3%" dense>Settings </v-toolbar>
      </v-card-title>
      <v-row>
        <v-col cols="6">
          <v-card height="fit-content" style="padding: 2%">
            <v-card-title>Create new user:</v-card-title>
            <v-card-actions style="padding-left: 1%; margin-left: 1px">
              <v-row style="margin-left: 2%; padding: 0%; width: fit-content">
                <v-form
                  ref="RegisterInfo"
                  validate-on="submit"
                  @submit.prevent
                  style="width: 100%"
                >
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Username:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="RegisterInfo.Username"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Username"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Email:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="RegisterInfo.Email"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Email"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Password:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="RegisterInfo.Password"
                    :rules="[rules.required]"
                    :append-inner-icon="newUserVis ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="() => (newUserVis = !newUserVis)"
                    :type="newUserVis ? 'password' : 'text'"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Password"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Repeat Password:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="RegisterInfo.RepeatPassword"
                    :rules="[rules.required]"
                    :append-inner-icon="newUserVis2 ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="() => (newUserVis2 = !newUserVis2)"
                    :type="newUserVis2 ? 'password' : 'text'"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Repeat Password"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Role:</v-card-text
                  >
                  <v-select
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="RegisterInfo.Role"
                    :items="roles"
                    :rules="[rules.required]"
                    hide-details
                    class="pa-0"
                  ></v-select>
                  <v-col class="ma-0 pa-0" cols="12">
                    <v-btn
                      variant="flat"
                      color="red"
                      type="submit"
                      style="height: 4vh; margin-top: 2%"
                      @click="RegisterUser()"
                      >Create User</v-btn
                    >
                  </v-col>
                  <v-col class="ma-0 pa-0" cols="12">
                    <v-alert
                      v-show="error"
                      :value="error"
                      color="red"
                      type="error"
                      style="width: 96%; margin-top: 2%"
                      transition="scale-transition"
                      >{{ this.error }}</v-alert
                    >
                    <v-alert
                      v-show="success"
                      :value="success"
                      color="green"
                      type="success"
                      style="width: 96%; margin-top: 2%"
                      transition="scale-transition"
                      >{{ this.success }}</v-alert
                    >
                  </v-col>
                </v-form>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6" style="padding-right: 1%">
          <v-card height="fit-content" style="padding: 2%">
            <v-card-title>Update Password:</v-card-title>
            <v-card-actions>
              <v-row style="margin-left: 2%; padding: 0%">
                <v-form
                  ref="UpdatePassword"
                  validate-on="submit"
                  @submit.prevent
                  style="width: 100%"
                >
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Old Password:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="UpdatePassword.oldPassword"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Old Password"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >New Password:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="UpdatePassword.NewPassword"
                    :rules="[rules.required]"
                    :append-inner-icon="newPassVis ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="() => (newPassVis = !newPassVis)"
                    :type="newPassVis ? 'password' : 'text'"
                    hide-details
                    single-line
                    class="pa-0"
                    label="New Password"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Repeat Password:</v-card-text
                  >
                  <v-text-field
                    variant="underlined"
                    density="comfortable"
                    style="margin-right: 4%"
                    required
                    v-model="UpdatePassword.RepeatPassword"
                    :rules="[rules.required]"
                    hide-details
                    type="password"
                    single-line
                    class="pa-0"
                    label="Repeat Password"
                  ></v-text-field>
                  <v-col class="pa-0" cols="12">
                    <v-btn
                      variant="flat"
                      color="red"
                      type="submit"
                      @click="ChangePassword()"
                      style="height: 4vh; margin-top: 2%"
                      >Update Password</v-btn
                    >
                  </v-col>
                  <v-col class="ma-0 pa-0" cols="12">
                    <v-alert
                      v-show="error2"
                      :value="error2"
                      color="red"
                      type="error"
                      style="width: 96%; margin-top: 2%"
                      transition="scale-transition"
                      >{{ this.error2 }}</v-alert
                    >
                    <v-alert
                      v-show="success2"
                      :value="success2"
                      color="green"
                      type="success"
                      style="width: 96%; margin-top: 2%"
                      transition="scale-transition"
                      >{{ this.success2 }}</v-alert
                    >
                  </v-col>
                </v-form>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6" v-if="checkIfPrivileged">
          <v-card height="fit-content" style="padding: 2%">
            <v-card-title>Delete User</v-card-title>
            <v-card-actions style="padding-left: 1%; margin-left: 1px">
              <v-row style="margin-left: 2%; padding: 0%; width: fit-content">
                <v-form
                  validate-on="submit"
                  @submit.prevent
                  style="width: 100%"
                >
                  <v-list v-model="selectedItem">
                    <v-list-item
                      v-for="user in users"
                      :key="user.username"
                      @click="deleteUser(user)"
                    >
                      <v-list-item-title
                        v-text="user.username"
                      ></v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-select
                    style="margin-right: 4%"
                    class="pa-0"
                    density="comfortable"
                    v-model="selectedItem"
                    :items="users"
                    label="Users"
                  ></v-select>
                  <v-btn
                    color="red"
                    variant="flat"
                    type="submit"
                    @click="deleteUser"
                    style="height: 4vh"
                    >Delete User</v-btn
                  >
                </v-form>
              </v-row>
            </v-card-actions>
            <!-- <v-alert
              v-show="error3"
              :value="error3"
              color="red"
              type="error"
              style="width: 96%; margin-top: 2%"
              transition="scale-transition"
              >{{ this.error2 }}</v-alert
            >
            <v-alert
              v-show="success3"
              :value="success3"
              color="green"
              type="success"
              style="width: 96%; margin-top: 2%"
              transition="scale-transition"
              >{{ this.success2 }}</v-alert
            > -->
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  data() {
    return {
      newPassVis: String,
      newUserVis: String,
      newUserVis2: String,
      RegisterInfo: {
        Username: null,
        Email: null,
        Password: null,
        RepeatPassword: null,
        Role: null,
      },
      UpdatePassword: {
        oldPassword: null,
        NewPassword: null,
        RepeatPassword: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      users: [],
      error: null,
      success: null,
      error2: null,
      success2: null,
      error3: null,
      success3: null,
      roles: [],
      checkIfPrivileged: false,
      selectedItem: null,
    };
  },
  mounted() {
    let comp = this;
    let decoded = VueJwtDecode.decode(this.$store.getters.getToken);
    if (decoded.role == "Admin") {
      console.log("getting users");
      this.checkIfPrivileged = true;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get("https://api.sboss.nl:8002/users")
        .then(function (response) {
          console.log(response.data);
          let users = response.data;

          // Filter out the user with the matching username
          users = users.filter((item) => item.username !== decoded.username);
          comp.users = users.filter((item) => item.role !== 1);
        })
        .catch(function () {});
    }
    this.axios.defaults.headers.common["Authorization"] =
      `Bearer ` + this.$store.getters.getToken;
    this.axios
      .get("https://api.sboss.nl:8002/roles")
      .then(function (response) {
        response.data.forEach((role) => {
          comp.roles.push(role.role);
        });
      })
      .catch(function () {});
  },
  methods: {
    RegisterUser() {
      this.error = null;
      this.success = null;
      if (
        this.RegisterInfo.Email == null ||
        this.RegisterInfo.Email == "" ||
        this.RegisterInfo.Password == "" ||
        this.RegisterInfo.Password == null ||
        this.RegisterInfo.RepeatPassword == "" ||
        this.RegisterInfo.RepeatPassword == null ||
        this.RegisterInfo.Username == "" ||
        this.RegisterInfo.Username == null
      ) {
        this.error = "Please fill in all fields!";
        return;
      }
      if (this.RegisterInfo.Password != this.RegisterInfo.RepeatPassword) {
        this.error = "Passwords not Equal";
        return;
      }
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .post("https://api.sboss.nl:8002/register", {
          Username: this.RegisterInfo.Username,
          Email: this.RegisterInfo.Email,
          Password: this.RegisterInfo.Password,
          Role: this.RegisterInfo.Role,
        })
        .then(function (response) {
          if (response.status == 200) {
            comp.success = "User created!";
          }
        })
        .catch(function (response) {
          comp.error = "Creating User Failed!";
          console.log(response);
        });
    },
    deleteUser() {
      if (this.selectedItem == null) {
        return;
      }
      let user = this.selectedItem;
      let comp = this;
      this.axios
        .delete("https://api.sboss.nl:8002/user/" + user.username)
        .then(function (response) {
          if (response.status == 200) {
            comp.success3 = "User deleted!";
          }
        })
        .catch(function (response) {
          comp.error3 = "Deleting User Failed!";
          console.log(response);
        });
    },
    ChangePassword() {
      this.error2 = null;
      this.success2 = null;
      if (
        this.UpdatePassword.oldPassword == null ||
        this.UpdatePassword.oldPassword == "" ||
        this.UpdatePassword.NewPassword == null ||
        this.UpdatePassword.NewPassword == "" ||
        this.UpdatePassword.RepeatPassword == null ||
        this.UpdatePassword.RepeatPassword == ""
      ) {
        this.error2 = "Please fill in all fields!";
        return;
      }
      if (
        this.UpdatePassword.NewPassword != this.UpdatePassword.RepeatPassword
      ) {
        this.error2 = "Passwords not Equal";
        return;
      }
      let comp = this;
      let username = null;
      let email = null;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      try {
        let decoded = VueJwtDecode.decode(this.$store.getters.getToken);
        username = decoded.username;
        email = decoded.email;
      } catch (err) {
        console.log(err);
      }
      this.axios
        .post("https://api.sboss.nl:8002/password/change", {
          Username: username,
          Email: email,
          Password: this.UpdatePassword.oldPassword,
          NewPassword: this.UpdatePassword.NewPassword,
        })
        .then(function (response) {
          if (response.status == 200) {
            comp.success2 = "Password Updated!";
          }
        })
        .catch(function (response) {
          comp.error2 = "Password Update Failed!";
          console.log(response);
        });
    },
  },
};
</script>

<style scoped>
.card2 {
  background-color: var(--v-card2);
}
</style>
