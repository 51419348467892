<template>
  <v-main style="padding: 0%">
    <v-card height="100%">
      <v-card-title style="padding: 0%">
        <v-toolbar style="padding-left: 0.3vw" dense>
          Settings for:
          <a
            style="
              margin-left: 0.5%;
              max-width: 20vw;
              font-size: 1em;
              vertical-align: middle;
              max-height: 80%;
            "
          >
            {{ this.fullname }}
          </a>
        </v-toolbar>
      </v-card-title>
      <v-row>
        <v-col cols="6">
          <v-card class="pa-4">
            <v-card-title>Loginlogs: </v-card-title>
            <v-data-table-virtual
              :headers="headerslogs"
              :items="visibleLoginLogs"
              :items-per-page="5"
              v-model:page="page"
              @page-count="handlePageCount"
              style="height: 35vh"
            >
            </v-data-table-virtual>

            <v-pagination
              v-model="currentPage"
              :length="Math.ceil(loginlogs.length / 5)"
              @input="handlePageChange"
            ></v-pagination>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="pa-4">
            <v-card-title> IP Whitelist (email only) </v-card-title>
            <v-data-table-virtual
              :headers="headers"
              :items="whitelistIps"
              :items-per-page="15"
              v-model:page="page2"
              class="elevation-1"
              height="100%"
              @page-count="pageCount2 = $event"
            >
              <template v-slot:item.active="{ item }">
                <v-checkbox-btn
                  @click="selectips(item)"
                  v-model="item.active"
                ></v-checkbox-btn>
              </template>
            </v-data-table-virtual>

            <v-pagination
              v-model="currentPage2"
              :length="Math.ceil(whitelistIps.length / 15)"
            ></v-pagination>
            <div class="text-center mt-3" style="margin-bottom: 2%">
              <!-- Use a div container to center the buttons -->
              <v-btn @click="deleteWhitelistIP()" color="red">Delete Ip</v-btn>
              <v-btn @click="openWhitelistDialog()" color="red" class="ml-3"
                >Add whitelist Ip</v-btn
              >
              <!-- Add margin-left for spacing -->
            </div>
            <v-alert
              v-show="error"
              :value="error"
              color="red"
              type="error"
              style="width: 15vw; text-align: left"
              transition="scale-transition"
              >{{ this.error }}</v-alert
            >
            <v-alert
              v-show="success2"
              :value="success2"
              color="green"
              type="success"
              style="width: 15vw; text-align: left; margin-bottom: 2%"
              transition="scale-transition"
              >{{ this.success }}</v-alert
            >
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="pa-4">
            <v-card-title>Send commands:</v-card-title>
            <div class="text-center mt-3">
              <!-- Use a div container to center the v-select -->
              <v-select
                style="width: 20vw"
                v-model="selectedCommand"
                :items="commandTypes"
                label="Select Command Type"
              ></v-select>
            </div>
            <v-row align="center" justify="center">
              <v-col
                v-if="valueNeeded"
                class="ma-0"
                cols="12"
                justify="center"
                align="center"
              >
                <v-text-field
                  label="Value"
                  v-model="commandBonusValue"
                  single-line
                ></v-text-field>
              </v-col>
              <v-btn @click="executeCommand" color="red">Send Command</v-btn>
              <v-col class="ma-0" cols="12" justify="center" align="center">
                <v-alert
                  v-show="error"
                  :value="error"
                  color="red"
                  type="error"
                  style="width: 25vw; text-align: left"
                  transition="scale-transition"
                  >{{ this.error4 }}</v-alert
                >
                <v-alert
                  v-show="success4"
                  :value="success4"
                  color="green"
                  type="success"
                  style="width: 25vw; text-align: left; margin-bottom: 2%"
                  transition="scale-transition"
                  >{{ this.success4 }}</v-alert
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-4">
            <v-card-title>Linked Emails</v-card-title>
            <v-data-table-virtual
              :headers="headersEmail"
              :items="excludedEmails"
              :items-per-page="5"
              v-model:page="page3"
              class="elevation-1"
              height="100%"
              @page-count="pageCount3 = $event"
            >
              <template v-slot:item.active="{ item }">
                <v-checkbox-btn
                  @click="selectemail(item)"
                  v-model="item.active"
                ></v-checkbox-btn>
              </template>
            </v-data-table-virtual>

            <v-pagination
              v-model="currentPage3"
              :length="Math.ceil(excludedEmails.length / 5)"
            ></v-pagination>
            <div class="text-center mt-3" style="margin-bottom: 2%">
              <!-- Use a div container to center the buttons -->
              <v-btn @click="deleteEmail()" color="red">Delete Email</v-btn>
              <v-btn @click="openEmailDialog()" color="red" class="ml-3"
                >Add Email</v-btn
              >
            </div>
            <v-alert
              v-show="error"
              :value="error"
              color="red"
              type="error"
              style="width: 15vw; text-align: left"
              transition="scale-transition"
              >{{ this.error }}</v-alert
            >
            <v-alert
              v-show="success2"
              :value="success2"
              color="green"
              type="success"
              style="width: 15vw; text-align: left; margin-bottom: 2%"
              transition="scale-transition"
              >{{ this.success }}</v-alert
            >
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="ipWhitelistDialog" max-width="500px">
      <v-card>
        <v-card-title>Email IP Whitelist</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="ipToWhitelist"
            label="IP Addresses,  one per line (press enter to goto next line)."
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="closeIpWhitelistDialog">Cancel</v-btn>
          <v-btn color="red" @click="whitelistIP()">Whitelist</v-btn>
        </v-card-actions>
        <v-alert
          v-show="success3"
          :value="success3"
          color="green"
          type="success"
          style="width: 96%; margin: 2%"
          transition="scale-transition"
          >Succes!
        </v-alert>
      </v-card>
    </v-dialog>
    <v-dialog v-model="emailDialog" max-width="500px">
      <v-card>
        <v-card-title>Add Email</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="emailToAdd"
            label="email adres,  seperate multiple by a , with no space"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="closeEmailDialog">Cancel</v-btn>
          <v-btn color="red" @click="addEmail()">add Email(s)</v-btn>
        </v-card-actions>
        <v-alert
          v-show="success3"
          :value="success3"
          color="green"
          type="success"
          style="width: 96%; margin: 2%"
          transition="scale-transition"
          >Succes!
        </v-alert>
      </v-card>
    </v-dialog>
    <v-overlay class="align-center justify-center" v-model="editconfigOverlay">
      <v-card
        style="
          max-width: 1000px;
          min-width: 40vw;
          height: 100%;
          text-align: center;
          padding: 0.5vw;
        "
      >
        <v-row>
          <v-col cols="12" justify="center">
            <VAceEditor
              v-model:value="editconfig"
              lang="json"
              style="height: 500px; width: 100%"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="padding-top: 0%; padding-bottom: 15px" cols="6">
            <v-btn style="width: 15vw" color="red" @click="SendConfig"
              >Send</v-btn
            >
          </v-col>
          <v-col style="padding-top: 0%; padding-bottom: 15px" cols="6">
            <v-btn style="width: 15vw" color="red" @click="CloseConfigChange"
              >Close</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-overlay>
  </v-main>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";

export default {
  components: {
    VAceEditor,
  },
  data() {
    return {
      commandTypes: [
        "start_honeypot",
        "get_status_honeypot",
        "stop_honeypot",
        "upgrade",
        "set_honeypot_protocols",
        "restart_os",
        "restart_api",
        "add_ip_whitlist",
        "remove_ip_whitlist",
        "start_ssh",
        "stop_ssh",
        "edit_config",
        "get_arp",
        "add_mac_whitlist",
        "remove_mac_whitlist",
        "get_count",
      ],
      error: null,
      error4: null,
      success: null,
      success2: null,
      success3: null,
      success4: null,
      content: null,
      valueNeeded: false,
      fullname: null,
      faketrue: true,
      honeypotsettings: null,
      commandBonusValue: null,
      ipWhitelistDialog: null,
      emailDialog: null,
      search: "",
      selectedCommand: null,
      page: 1, // Current page of the virtual table
      pageCount: 0, // Total number of pages
      loginlogs: [],
      page2: 1,
      page3: 1,
      pageCount2: 0,
      pageCount3: 0,
      selectedIps: [],
      whitelistIps: [],
      excludedEmails: [],
      editconfig: null,
      editconfigOverlay: false,
      pollingInterval: null,
      itemsPerPage: 10,
      ipToWhitelist: null,
      emailToAdd: null,
      headerslogs: [
        { title: "User", align: "start", value: "user", sortable: true },
        {
          title: "Timestamp",
          align: "start",
          value: "timeStamp",
          sortable: true,
        },
      ],
      headers: [
        { title: "IP", align: "start", value: "ip", sortable: true },
        { title: "Select", key: "active" },
      ],
      headersEmail: [
        { title: "email", align: "start", value: "email", sortable: true },
        { title: "Select", key: "active" },
      ],
      items: [],
    };
  },
  watch: {
    selectedCommand(newCommand) {
      // Adjust the condition based on your command logic
      if (
        newCommand === "add_ip_whitlist" ||
        newCommand === "remove_ip_whitlist" ||
        newCommand === "set_honeypot_protocols" ||
        newCommand === "remove_mac_whitlist" ||
        newCommand === "add_mac_whitlist"
      ) {
        this.valueNeeded = true;
      } else {
        this.valueNeeded = false;
      }
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(value) {
        this.page = value;
      },
    },
    currentPage2: {
      get() {
        return this.page2;
      },
      set(value) {
        this.page2 = value;
      },
    },
    currentPage3: {
      get() {
        return this.page3;
      },
      set(value) {
        this.page3 = value;
      },
    },
    visibleLoginLogs() {
      const startIndex = (this.page - 1) * 6;
      const endIndex = startIndex + 6;
      return this.loginlogs.slice(startIndex, endIndex);
    },
  },
  mounted() {
    console.log("honeypotsettings mount triggered");
    if (
      this.$store.getters.getToken == "" ||
      this.$store.getters.getToken == null
    ) {
      console.log("getting token from sessionStorage");
      this.$store.commit("setToken", sessionStorage.getItem("jwtToken"));
    }
    console.log(this.$store.getters.getToken);
    if (
      this.$store.getters.getPot == "" ||
      this.$store.getters.getPot == null
    ) {
      console.log("getting pot from sessionstorage");
      this.$store.commit(
        "setPot",
        JSON.parse(sessionStorage.getItem("ChosenPot"))
      );
    }
    console.log(this.$store.getters.getPot);
    this.fullname =
      this.$store.getters.getPot.License.Company.name +
      " - " +
      this.$store.getters.getPot.hostname;
    this.getIps();
    this.getLoginLogs();
    this.getEmails();
  },
  methods: {
    CloseConfigChange() {
      this.editconfigOverlay = false;
    },
    handlePageCount(count) {
      this.pageCount = count;
    },
    handlePageChange(page) {
      this.page = page;
    },
    getIps() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get(
          "https://api.sboss.nl:8002/ipwhitelists/" +
            this.$store.getters.getPot.license
        )
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            comp.whitelistIps = response.data;
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    getEmails() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get(
          "https://api.sboss.nl:8002/emails/company/" +
            this.$store.getters.getPot.license
        )
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            comp.excludedEmails = response.data;
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    getLoginLogs() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get(
          "https://api.sboss.nl:8002/loginlogs/" +
            this.$store.getters.getPot.license
        )
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            comp.loginlogs = response.data;
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    startPollingStatus() {
      this.pollingInterval = setInterval(this.getHoneypotStatus, 5000); // Poll every 5 seconds
    },
    startPollingArp() {
      this.pollingInterval = setInterval(this.getHoneypotArp, 5000); // Poll every 5 seconds
    },
    startPollingCount() {
      this.pollingInterval = setInterval(this.getHoneypotCount, 5000); // Poll every 5 seconds
    },
    startPollingConfig() {
      this.pollingInterval = setInterval(this.getHoneypotConfig, 5000); // Poll every 5 seconds
    },
    async getHoneypotCount() {
      let comp = this;
      try {
        this.axios
          .get(
            "https://api.sboss.nl:8002/count/" +
              this.$store.getters.getPot.license
          )
          .then(async function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.downloadFile(response.data.honeypotstatus, "count.txt"); // Download the data as a text file
              comp.axios.delete(
                "https://api.sboss.nl:8002/count/" +
                  comp.$store.getters.getPot.license
              );
              clearInterval(comp.pollingInterval); // Stop the polling
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
        } else {
          console.log("An error occurred: ", error);
          clearInterval(this.pollingInterval); // Stop the polling on unexpected error
        }
      }
    },
    async getHoneypotStatus() {
      let comp = this;
      try {
        this.axios
          .get(
            "https://api.sboss.nl:8002/honeypotstatus/" +
              this.$store.getters.getPot.license
          )
          .then(async function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.downloadFile(response.data.honeypotstatus, "status.txt"); // Download the data as a text file
              comp.axios.delete(
                "https://api.sboss.nl:8002/honeypotstatus/" +
                  comp.$store.getters.getPot.license
              );
              clearInterval(comp.pollingInterval); // Stop the polling
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
        } else {
          console.log("An error occurred: ", error);
          clearInterval(this.pollingInterval); // Stop the polling on unexpected error
        }
      }
    },
    async getHoneypotArp() {
      let comp = this;
      try {
        this.axios
          .get(
            "https://api.sboss.nl:8002/arp/" +
              this.$store.getters.getPot.license
          )
          .then(async function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.downloadFile(response.data.value, "arp.txt"); // Download the data as a text file
              comp.axios.delete(
                "https://api.sboss.nl:8002/arp/" + response.data.id
              );
              clearInterval(comp.pollingInterval); // Stop the polling
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
        } else {
          console.log("An error occurred: ", error);
          clearInterval(this.pollingInterval); // Stop the polling on unexpected error
        }
      }
    },
    async getHoneypotConfig() {
      let comp = this;
      try {
        this.axios
          .get(
            "https://api.sboss.nl:8002/honeypotconfig/" +
              this.$store.getters.getPot.license
          )
          .then(async function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.editconfig = response.data.honeypotconfig;
              comp.editconfigOverlay = true;
              comp.axios.delete(
                "https://api.sboss.nl:8002/honeypotconfig/" +
                  comp.$store.getters.getPot.license
              );
              clearInterval(comp.pollingInterval); // Stop the polling
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
        } else {
          console.log("An error occurred: ", error);
          clearInterval(this.pollingInterval); // Stop the polling on unexpected error
        }
      }
    },
    async getHoneypotStatusBefore() {
      let comp = this;
      try {
        await this.axios
          .get(
            "https://api.sboss.nl:8002/honeypotstatus/" +
              this.$store.getters.getPot.license
          )
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.downloadFile(response.data.honeypotstatus, "status.txt"); // Download the data as a text file
              comp.axios.delete(
                "https://api.sboss.nl:8002/honeypotstatus/" +
                  comp.$store.getters.getPot.license
              );
              return false;
            }
          })
          .catch((error) => {
            console.log(error);
            return true;
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
          return true;
        } else {
          console.log("An error occurred: ", error);
          return false;
        }
      }
      return true;
    },
    async getHoneypotConfigBefore() {
      let comp = this;
      try {
        await this.axios
          .get(
            "https://api.sboss.nl:8002/honeypotconfig/" +
              this.$store.getters.getPot.license
          )
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.editconfig = response.data.honeypotconfig;
              comp.editconfigOverlay = true;
              comp.axios.delete(
                "https://api.sboss.nl:8002/honeypotconfig/" +
                  comp.$store.getters.getPot.license
              );
              return false;
            }
          })
          .catch((error) => {
            console.log(error);
            return true;
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
          return true; // No data, send command
        } else {
          console.log("An error occurred: ", error);
          return false; // No data, send command
        }
      }
      return true;
    },
    async getHoneypotArpBefore() {
      let comp = this;
      try {
        await this.axios
          .get(
            "https://api.sboss.nl:8002/arp/" +
              this.$store.getters.getPot.license
          )
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              comp.downloadFile(response.data.honeypotstatus, "status.txt");
              comp.axios.delete(
                "https://api.sboss.nl:8002/arp/" +
                  comp.$store.getters.getPot.license
              );
              return false;
            }
          })
          .catch((error) => {
            console.log(error);
            return true;
          });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Waiting for data...");
          return true; // No data, send command
        } else {
          console.log("An error occurred: ", error);
          return false; // Error, dont send command
        }
      }
      return true;
    },
    downloadFile(data, name) {
      const blob = new Blob([data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    SendConfig() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .post("https://api.sboss.nl:8002/command", {
          license: this.$store.getters.getPot.license,
          command: "update_config",
          value: this.editconfig,
        })
        .then(async function (response) {
          console.log("update_config");
          if (response.status == 200) {
            comp.success4 =
              "Succesfully send new config, please wait 2 minutes!";
            comp.editconfigOverlay = false;
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    async executeCommand() {
      // Implement logic to execute command using this.commandToExecute
      console.log("Executing Command:", this.selectedCommand);
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      let shouldSendCommand = true;

      console.log("checking existing data...");
      if (this.selectedCommand == "edit_config") {
        shouldSendCommand = await this.getHoneypotConfigBefore();
      } else if (this.selectedCommand == "get_status_honeypot") {
        shouldSendCommand = await this.getHoneypotStatusBefore();
      } else if (this.selectedCommand == "get_arp") {
        shouldSendCommand = await this.getHoneypotArpBefore();
      }
      console.log(shouldSendCommand);

      if (!shouldSendCommand) {
        console.log(
          "data already found, might be old data! command not needed..."
        );
        return;
      }
      this.axios
        .post("https://api.sboss.nl:8002/command", {
          license: this.$store.getters.getPot.license,
          command: this.selectedCommand,
          value: this.commandBonusValue,
        })
        .then(async function (response) {
          console.log(comp.selectedCommand);
          if (response.status == 200) {
            if (comp.selectedCommand == "get_status_honeypot") {
              comp.success4 = "this might take 2 minutes, please wait!";
              comp.startPollingStatus();
            } else if (comp.selectedCommand == "edit_config") {
              comp.success4 = "this might take 2 minutes, please wait!";
              comp.startPollingConfig();
            } else if (comp.selectedCommand == "get_arp") {
              comp.success4 = "this might take 2 minutes, please wait!";
              comp.startPollingArp();
            } else if (comp.selectedCommand == "get_count") {
              comp.success4 = "this might take 2 minutes, please wait!";
              comp.startPollingCount();
            } else {
              comp.success4 =
                "Succesfully send message, please wait 2 minutes!";
            }
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    refreshitems() {
      this.getIps();
      this.getLoginLogs();
    },
    selectips(item) {
      const index = this.selectedIps.indexOf(item);
      if (index === -1) {
        // Item is not in the selectedIps array, so add it
        this.selectedIps.push(item);
      } else {
        // Item is already in the selectedIps array, so remove it
        this.selectedIps.splice(index, 1);
      }
      console.log(this.selectedIps);
    },
    selectemail(item) {
      const index = this.excludedEmails.indexOf(item);
      if (index === -1) {
        // Item is not in the selectedIps array, so add it
        this.excludedEmails.push(item);
      } else {
        // Item is already in the selectedIps array, so remove it
        this.excludedEmails.splice(index, 1);
      }
      console.log(this.excludedEmails);
    },
    formatTimestamp(timestamp) {
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };

      const formattedDate = new Date(timestamp).toLocaleDateString(
        "en-US",
        options
      );
      return formattedDate.replace(",", ""); // Remove comma after the day
    },
    closeIpWhitelistDialog() {
      this.ipWhitelistDialog = false;
      this.ipToWhitelist = "";
    },
    closeEmailDialog() {
      this.emailDialog = false;
      this.emailToAdd = "";
    },
    openWhitelistDialog() {
      this.ipWhitelistDialog = true;
    },
    openEmailDialog() {
      this.emailDialog = true;
    },
    whitelistIP() {
      // Implement logic to whitelist IP using this.ipToWhitelist
      console.log("Whitelisting IP:", this.ipToWhitelist);
      const ips = this.ipToWhitelist.split("\n").map((ip) => ip.trim());

      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;

      // Use a loop to whitelist each IP address
      ips.forEach((ip) => {
        this.axios
          .post("https://api.sboss.nl:8002/ipwhitelist", {
            license: this.$store.getters.getPot.license,
            ip: ip,
          })
          .then(async function (response) {
            if (response.status == 200) {
              comp.success3 = true;
              comp.refreshitems();
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      });
    },
    addEmail() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;

      this.axios
        .post("https://api.sboss.nl:8002/emails", {
          license: this.$store.getters.getPot.license,
          email: this.emailToAdd,
        })
        .then(async function (response) {
          if (response.status == 200) {
            comp.success3 = true;
            comp.refreshitems();
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    deleteWhitelistIP() {
      // Implement logic to whitelist IP using this.ipToWhitelist
      const ips = this.selectedIps;

      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;

      // Use a loop to whitelist each IP address
      ips.forEach((ip) => {
        this.axios
          .delete("https://api.sboss.nl:8002/ipwhitelist/" + ip.id)
          .then(async function (response) {
            if (response.status == 200) {
              comp.success2 = true;
              this.refreshitems();
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      });
    },
  },
  deleteEmail() {
    // Implement logic to whitelist IP using this.ipToWhitelist
    const emails = this.excludedEmails;

    let comp = this;
    this.axios.defaults.headers.common["Authorization"] =
      `Bearer ` + this.$store.getters.getToken;

    // Use a loop to whitelist each IP address
    emails.forEach((email) => {
      this.axios
        .delete("https://api.sboss.nl:8002/email/" + email.id)
        .then(async function (response) {
          if (response.status == 200) {
            comp.success2 = true;
            this.refreshitems();
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    });
  },
};
</script>

<style>
.text-center {
  text-align: center; /* Center align the content */
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center content horizontally */
}
</style>
