<template>
  <v-app-bar flat color="topbar">
    <template v-slot:append>
      <v-menu offset="13px" :close-on-content-click="false">
        <template v-slot:activator="{ props, isActive }">
          <div class="hover" v-bind="props">
            <v-avatar
              image="https://cdn.britannica.com/30/150930-120-D3D93F1E/lion-Namibia.jpg"
            ></v-avatar>
            <v-icon
              :icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            ></v-icon>
          </div>
        </template>
        <v-card rounded="0" min-width="250px">
          <v-list rounded="0" density="compact" nav>
            <v-list-item
              prepend-avatar="https://cdn.britannica.com/30/150930-120-D3D93F1E/lion-Namibia.jpg"
              :title="user.username"
              :subtitle="user.email"
            />
          </v-list>
          <v-divider />
          <v-list rounded="0" density="compact" nav>
            <v-list-item
              prepend-icon="mdi-theme-light-dark"
              title="Theme"
              @click="toggleTheme"
            >
              <template v-slot:append>
                <v-switch
                  hide-details
                  density="compact"
                  inset
                  flat
                  color="#f5dd02"
                  false-icon="mdi-weather-night"
                  true-icon="mdi-weather-sunny"
                  true-value="light"
                  false-value="dark"
                  v-model="theme"
                ></v-switch>
              </template>
            </v-list-item>
            <v-list-item
              prepend-icon="mdi-logout"
              title="Sign out"
              @click="doLogout"
            />
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import { defineComponent } from "vue";
import { onMounted } from "vue";
import VueJwtDecode from "vue-jwt-decode";
import { useTheme } from "vuetify/lib/framework.mjs";

export default defineComponent({
  setup() {
    onMounted(() => {
      let th;
      if (localStorage.getItem("theme") != null) {
        th = localStorage.getItem("theme");
      } else {
        th = "dark";
      }

      theme.global.name.value = th ? th : "dark";
      localStorage.setItem("theme", theme.global.name.value);
    });
    const theme = useTheme();
    const toggleTheme = () => {
      theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";
      localStorage.setItem("theme", theme.global.name.value);
    };
    return {
      toggleTheme,
    };
  },
  created() {
    this.getUserDetails();
  },
  data: function () {
    return {
      theme: null,
      user: {},
    };
  },
  methods: {
    doLogout() {
      sessionStorage.removeItem("jwtToken");
      this.$store.commit("logOut");
      this.$router.push("/");
    },
    getUserDetails() {
      // get token from sessionStorage
      let token = sessionStorage.getItem("jwtToken");
      try {
        //decode token here and attach to the user object
        let decoded = VueJwtDecode.decode(token);
        this.user = decoded;
      } catch (error) {
        // return error in production env
        console.log(error, "error from decoding token");
      }
    },
  },
});
</script>

<style scoped>
.hover {
  cursor: pointer;
}
</style>
