<template>
  <div
    style="padding: 0.5%"
    v-if="loading"
    class="d-flex fill-height align-center justify-center"
  >
    <v-progress-circular size="64" thickness="10" indeterminate />
  </div>
  <v-row style="padding: 0.5%" v-else>
    <v-col cols="12" class="pb-0">
      <v-toolbar color="transparent">
        <template v-slot:title>
          <v-toolbar-title class="font-weight-bold">Honeypots</v-toolbar-title>
        </template>
      </v-toolbar>
    </v-col>
    <v-col cols="3">
      <v-card min-height="150px" height="150px" rounded="0">
        <v-divider vertical thickness="5" color="primary"></v-divider>
        <v-card-text class="h-100 d-flex flex-column justify-center px-8">
          <div class="font-weight-bold text-h4">{{ items.length }}</div>
          <div class="font-weight-light text-h6">Total Honeypots</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card min-height="150px" height="150px" rounded="0">
        <v-divider vertical thickness="5" color="grey"></v-divider>
        <v-card-text class="h-100 d-flex flex-column justify-center px-8">
          <div class="font-weight-bold text-h4">
            {{ items.filter((x) => !x.status).length }}
          </div>
          <div class="font-weight-light text-h6">Inactive Honeypots</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card min-height="150px" height="150px" rounded="0">
        <v-divider vertical thickness="5" color="grey"></v-divider>
        <v-card-text class="h-100 d-flex flex-column justify-center px-8">
          <div class="font-weight-bold text-h4">
            {{ items.filter((x) => !x.License.active).length }}
          </div>
          <div class="font-weight-light text-h6">Inactive Licenses</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card min-height="150px" height="150px" rounded="0">
        <v-divider vertical thickness="5" color="grey"></v-divider>
        <v-card-text class="h-100 d-flex flex-column justify-center px-8">
          <div class="font-weight-bold text-h4">
            {{
              items
                .map((x) => x.messageamount)
                .flat()
                .filter((value) => !isNaN(value)) // Filter out error values
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                )
            }}
          </div>
          <div class="font-weight-light text-h6">Total Messages</div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card rounded="0">
        <v-card-text>
          <v-text-field
            v-model="search"
            placeholder="Search honeypot..."
            variant="outlined"
            hide-details
            density="compact"
          ></v-text-field>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="visiblePots"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:item.License.active="{ item }">
              <v-icon
                :icon="item.License.active ? 'mdi-check' : 'mdi-close'"
                :color="item.License.active ? 'success' : 'error'"
              />
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon
                :icon="item.status ? 'mdi-check' : 'mdi-close'"
                :color="item.status ? 'success' : 'error'"
              />
            </template>
            <template v-slot:bottom>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(items.length / itemsPerPage)"
                ></v-pagination>
              </div>
            </template>
            <template v-slot:no-data>
              <div class="d-flex justify-center flex-column align-center">
                <v-img
                  width="250px"
                  max-width="250px"
                  :draggable="false"
                  src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-8867280-7265556.png?f=webp"
                />
                <div>We couldn't find any data with that searchterm.</div>
              </div>
            </template>
            <template v-slot:item.menu="{ item }">
              <v-menu location="start" offset-y>
                <template v-slot:activator="{ props }">
                  <v-icon @click="openMenu(item)" v-bind="props"
                    >mdi-menu-down</v-icon
                  >
                </template>

                <v-list>
                  <!-- Add your menu items here -->
                  <v-list-item
                    v-for="(menuOption, index) in menuOptions"
                    :key="index"
                  >
                    <v-list-item-title
                      @click="handleMenuOption(item, menuOption)"
                    >
                      {{ menuOption.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-overlay
    class="align-center justify-center"
    style="text-align: center"
    v-model="deletePopup"
  >
    <v-card style="padding: 2%">
      <v-row
        ><v-col cols="12"
          ><v-card-title
            >Are you sure you want to delete this?</v-card-title
          ></v-col
        ><v-col cols="6"
          ><v-btn @click="deleteItemNo" color="red" width="75%"
            >No</v-btn
          ></v-col
        ><v-col cols="6"
          ><v-btn @click="deleteItemYes" color="red" width="75%"
            >Yes</v-btn
          ></v-col
        >
        <v-alert
          v-show="error3"
          :value="error3"
          color="red"
          type="error"
          style="width: 96%; margin-top: 2%"
          transition="scale-transition"
          >{{ this.error3 }}</v-alert
        >
        <v-alert
          v-show="success3"
          :value="success3"
          color="green"
          type="success"
          style="width: 96%; margin-top: 2%"
          transition="scale-transition"
          >{{ this.success3 }}</v-alert
        ></v-row
      ></v-card
    ></v-overlay
  >
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  async beforeMount() {
    this.loading = true;
    console.log("mounted triggered");
    if (
      this.$store.getters.getToken == "" ||
      this.$store.getters.getToken == null
    ) {
      this.$store.commit("setToken", sessionStorage.getItem("jwtToken"));
    }
    await this.getPots().then(() => {
      this.loading = !this.loading;
    });
    this.intervalId = setInterval(this.fetchAndSetData, 90000);
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  computed: {
    visiblePots() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.items.slice(start, end);
    },
  },
  methods: {
    decodeToken(token) {
      try {
        // Verify and decode the token
        const decoded = VueJwtDecode.decode(token); // Replace 'your-secret-key' with your actual secret key
        return decoded;
      } catch (error) {
        // Handle any errors, e.g., token expired or invalid
        console.error("Error decoding token:", error);
        return null;
      }
    },
    async fetchAndSetData() {
      try {
        await this.getPots();
        const token = sessionStorage.getItem("jwtToken");
        if (token) {
          const decodedToken = this.decodeToken(token);
          const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();
          const tokenRefreshThreshold = 4 * 60 * 60 * 1000; // 9 hour in milliseconds
          if (expirationTime - currentTime < tokenRefreshThreshold) {
            // Token is about to expire, refresh it
            console.log("refresh token");
            this.refreshToken(token);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    refreshToken(token) {
      this.axios.defaults.headers.common["Authorization"] = `Bearer ` + token;
      let comp = this;
      this.axios
        .post("https://api.sboss.nl:8002/refreshtoken")
        .then(function (response) {
          let newtoken = response.data;
          console.log(newtoken);
          sessionStorage.setItem("jwtToken", newtoken);
          comp.$store.commit("setToken", newtoken);
        })
        .catch(function () {});
    },
    async getPots() {
      console.log("getting pots");
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get("https://api.sboss.nl:8002/pots")
        .then(async function (response) {
          if (response.status == 200) {
            const genitems = response.data;
            await Promise.all(
              genitems.map(async (item) => {
                try {
                  const oldTime = new Date(item.lastactive);
                  const currentTime = new Date();
                  const timeDifference = currentTime - oldTime;
                  const timeDifferenceInMinutes = timeDifference / (1000 * 60);

                  if (item.hostname == "") {
                    item.hostname = "Old Local api";
                  }

                  if (timeDifferenceInMinutes > 4) {
                    item.status = false;
                  } else {
                    item.status = true;
                  }

                  if (item.License.active) {
                    item.License.active = true;
                  } else {
                    item.License.active = false;
                  }
                } catch (error) {
                  console.log(error.message);
                  // Handle the error or set a default value for item.messages
                  item.messages = "error";
                }
              })
            );

            comp.items = genitems;
            console.log(genitems);
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    handleClick(item) {
      // You can access item properties like item.id, item.license, etc.
      // For example:
      sessionStorage.setItem("ChosenPot", JSON.stringify(item));
      this.$store.commit("setPot", item);
      this.$router.push("/messages");
    },
    deleteItem(item) {
      console.log("delete");
      this.deletePopup = true;
      this.deleteItemToDelete = item;
    },
    deleteItemNo() {
      this.deletePopup = false;
      this.deleteItemToDelete = null;
    },
    deleteItemYes() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .delete("https://api.sboss.nl:8002/pot/" + this.deleteItemToDelete.id)
        .then(async function (response) {
          if (response.status == 200) {
            console.log("Request succes!");
            comp.success3 = "Delete succesfull!";
            comp.sleep(2000).then(() => {
              comp.deletePopup = false;
            });
          }
        })
        .catch((error) => {
          console.log("error = " + error);
          comp.error3 = "Delete call failed!";
        });

      // TODO --------------------------------------------------------------------------------------------------
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    honeySettings(item) {
      // You can access item properties like item.id, item.license, etc.
      // For example:
      sessionStorage.setItem("ChosenPot", JSON.stringify(item));
      this.$store.commit("setPot", item);
      this.$router.push("/honeysettings");
    },
    openMenu(item) {
      console.log("selecting item");
      this.selectedRow = item;
    },
    handleMenuOption(item, menuOption) {
      if (!menuOption || !menuOption.action) {
        console.error("Invalid menuOption:", menuOption);
        return;
      }

      const action = menuOption.action.toLowerCase();

      if (action === "messages") {
        this.handleClick(item);
      } else if (action === "settings") {
        this.honeySettings(item);
      } else if (action === "delete") {
        this.deleteItem(item);
      } else {
        console.error("Invalid action:", action);
      }
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          title: "Honeypot Name",
          align: "start",
          value: "hostname",
          sortable: false,
        },
        {
          title: "License Key",
          align: "start",
          value: "license",
          sortable: false,
        },
        {
          title: "License Status",
          align: "start",
          sortable: true,
          value: "License.active",
        },
        {
          title: "Alive",
          align: "start",
          value: "status",
          sortable: true,
        },
        {
          title: "Company",
          align: "start",
          value: "License.Company.name",
          sortable: true,
        },
        {
          title: "Messages",
          align: "start",
          value: "messageamount",
          sortable: true,
        },
        {
          title: "Version",
          align: "start",
          value: "version",
          sortable: true,
        },
        {
          text: "Menu",
          value: "menu",
          sortable: false,
        },
      ],
      deletePopup: false,
      deleteItemToDelete: null,
      items: [],
      loading: null,
      itemsPerPage: 8,
      page: 1,
      error3: null,
      success3: null,
      menuOptions: [
        { text: "Messages", action: "messages" },
        { text: "Honeypot Settings", action: "settings" },
        { text: "Delete", action: "delete" },
        // Add more options as needed
      ],
      selectedRow: null,
      success: false,
    };
  },
};
</script>

<style scoped>
::v-deep(.v-divider--vertical) {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
}
</style>
