import { createStore } from "vuex";

export default createStore({
  state: {
    jwtToken: null,
    employerWait: true,
    chosenPot: null,
    theme: "dark",
  },
  getters: {
    getCode: (state) => state.logincode,
    getToken: (state) => state.jwtToken,
    getPot: (state) => state.chosenPot,
    getTheme: (state) => state.theme,
  },
  mutations: {
    setToken(state, token) {
      state.jwtToken = token;
    },
    logOut(state) {
      state.jwtToken = null;
    },
    setPot(state, value) {
      state.chosenPot = value;
    },
    changeTheme(state) {
      state.theme = state.theme ? "light" : "dark";
    },
  },
  actions: {},
  modules: {},
});
