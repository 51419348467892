<template>
  <v-navigation-drawer expand-on-hover :rail="rail" color="sidebar">
    <v-list>
      <v-list-item prepend-avatar="/logo.png">
        <template v-slot:title>
          <v-list-item-title class="text-uppercase font-weight-bold"
            ><span style="color: red">Shell</span>back</v-list-item-title
          >
        </template>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-tooltip
        v-for="route in routesWithIcons"
        :key="route.path"
        :text="route.name"
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="route.name"
            :to="route.path"
            exact
          >
            <template v-slot:prepend>
              <v-icon>{{ route.meta.icon }}</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-tooltip>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "SideBar",
  data() {
    return {
      rail: true,
      mini: true,
    };
  },
  created() {
    this.getUserDetails();
  },
  props: {
    routes: null,
  },
  computed: {
    routesWithIcons() {
      return this.filterRoutesWithIcons(this.routes);
    },
  },
  methods: {
    filterRoutesWithIcons(routes) {
      if (!routes) return []; // Handle the case where routes is null or undefined
      let filteredRoutes = [];

      routes.forEach((route) => {
        if (route.meta && route.meta.icon) {
          // If the route itself has an icon, add it directly
          filteredRoutes.push(route);
        } else if (route.children && route.children.length > 0) {
          // If the route has children, recursively filter children with icons
          const filteredChildren = this.filterRoutesWithIcons(route.children);
          if (filteredChildren.length > 0) {
            // Only add the route if at least one child has an icon
            filteredRoutes.push({
              ...route,
              children: filteredChildren,
            });
          }
        }
      });

      return filteredRoutes;
    },
    getUserDetails() {
      // get token from localstorage
      let token = this.$store.getters.getToken;
      try {
        //decode token here and attach to the user object
        let decoded = VueJwtDecode.decode(token);
        this.user = decoded;
      } catch (error) {
        // return error in production env
        console.log(error, "error from decoding token");
      }
    },
  },
};
</script>

<style>
.list-container {
  display: flex;
  flex-direction: column;
  max-height: 100vh; /* Set a maximum height, adjust as needed */
}

.bottom-item {
  margin-top: auto; /* Push the item to the bottom */
}
</style>
