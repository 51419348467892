<template>
  <Navigation :routes="this.routes" />
  <AppBar />
  <v-main
    app
    style="
      --v-layout-right: 0px;
      background: rgb(var(--v-theme-background-color));
    "
    class="fill-height"
  >
    <v-divider />
    <v-container
      class="align-start fill-height-custom"
      fluid
      style="padding: 0%"
    >
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
import Navigation from "@/components/SideBar.vue";
import AppBar from "@/components/AppBar.vue";
import { routes } from "@/router/index";

export default {
  components: {
    Navigation,
    AppBar,
  },
  data() {
    return {
      routes: null,
    };
  },
  mounted() {
    this.routes = routes.find((x) => x.path == "/home").children;
    this.routes = this.routes.filter((route) => route.path !== "/messages");
  },
};
</script>

<style scoped>
.fill-height-custom {
  height: calc(100vh - 65px);
  overflow: auto;
}
</style>
