import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import LoginPage from "../views/LoginView.vue";
import HomePage from "../views/HomeView.vue";
import UserManagementPage from "../views/SettingsView.vue";
import MessagesPage from "../views/MessagesView.vue";
import Managementpage from "../views/ManagementView.vue";
import DefaultView from "../views/DefaultView.vue";
import HoneySettings from "../views/honeypotSettingsView.vue";
import VueJwtDecode from "vue-jwt-decode";
import axios from "axios";

export const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/home",
    component: DefaultView,
    meta: {
      requireToken: true,
    },
    children: [
      {
        path: "/home",
        name: "Home",
        component: HomePage,
        meta: {
          icon: "mdi-beehive-outline",
        },
      },
      {
        path: "/manage",
        name: "Management",
        component: Managementpage,
        meta: {
          icon: "mdi-account-outline",
        },
      },
      {
        path: "/usermanagement",
        name: "User Management",
        component: UserManagementPage,
        meta: {
          icon: "mdi-card-account-details-outline",
        },
      },
      {
        path: "/messages",
        name: "Messages",
        component: MessagesPage,
      },
      {
        path: "/honeysettings",
        name: "HoneySettings",
        component: HoneySettings,
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("jwtToken");
  console.log("check for refresh");
  if (token) {
    const decodedToken = decodeToken(token);
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    const tokenRefreshThreshold = 4 * 60 * 60 * 1000; // 4 hour in milliseconds
    if (currentTime > expirationTime) {
      next({ name: "Login" });
    }
    if (expirationTime - currentTime < tokenRefreshThreshold) {
      // Token is about to expire, refresh it
      console.log("refresh token");
      refreshToken(token);
    } else {
      store.commit("setToken", token);
    }
  }
  if (to.meta.requireToken) {
    if (store.getters.getToken == null) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

function decodeToken(token) {
  try {
    // Verify and decode the token
    const decoded = VueJwtDecode.decode(token); // Replace 'your-secret-key' with your actual secret key
    return decoded;
  } catch (error) {
    // Handle any errors, e.g., token expired or invalid
    console.error("Error decoding token:", error);
    return null;
  }
}
// Define your refreshToken method here
function refreshToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ` + token;
  let comp = this;
  axios
    .post("https://shest.sboss.nl:8002/refreshtoken")
    .then(function (response) {
      let newtoken = response.data;
      console.log(newtoken);
      sessionStorage.setItem("jwtToken", newtoken);
      comp.$store.commit("setToken", newtoken);
    })
    .catch(function () {});
}

export default router;
