<template>
  <v-main style="padding: 0%; height: 100%">
    <v-card fill-height height="100%" class="card2">
      <v-card-title style="padding: 0%">
        <v-toolbar style="padding-left: 3%" dense
          >Company and License page</v-toolbar
        >
      </v-card-title>
      <v-row style="padding-top: 1%">
        <v-col style="padding-top: 0%" cols="6">
          <v-card height="auto" style="padding: 2%; padding-top: 0%">
            <v-card-title>Create new Company:</v-card-title>
            <v-card-actions style="padding-left: 1%; margin-left: 1px">
              <v-row style="margin-left: 2%; padding: 0%; width: fit-content">
                <v-form
                  ref="CompanyInfo"
                  validate-on="submit"
                  @submit.prevent
                  style="width: 100%"
                >
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Company name:</v-card-text
                  >
                  <v-text-field
                    density="compact"
                    style="margin-right: 4%"
                    required
                    v-model="CompanyInfo.Companyname"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Company name"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >KVK number:</v-card-text
                  >
                  <v-text-field
                    density="compact"
                    style="margin-right: 4%"
                    required
                    v-model="CompanyInfo.Kvknum"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="KVK number"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Email:</v-card-text
                  >
                  <v-text-field
                    density="compact"
                    style="margin-right: 4%"
                    required
                    v-model="CompanyInfo.Email"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Email"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >Phone Number:</v-card-text
                  >
                  <v-text-field
                    density="compact"
                    style="margin-right: 4%"
                    required
                    v-model="CompanyInfo.Phone"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="Phone Number"
                  ></v-text-field>
                  <v-card-text style="margin-top: 2%; height: 2vh" class="pa-0"
                    >BTW Number:</v-card-text
                  >
                  <v-text-field
                    density="compact"
                    style="margin-right: 4%"
                    required
                    v-model="CompanyInfo.BtwNum"
                    :rules="[rules.required]"
                    hide-details
                    single-line
                    class="pa-0"
                    label="BTW Number"
                  ></v-text-field>
                  <v-col class="ma-0 pa-0" cols="12">
                    <v-alert
                      v-show="CompanyError"
                      :value="CompanyError"
                      color="red"
                      type="error"
                      style="width: 96%; margin-top: 2%"
                      transition="scale-transition"
                      >{{ this.CompanyError }}</v-alert
                    >
                    <v-alert
                      v-show="CompanySuccess"
                      :value="CompanySuccess"
                      color="green"
                      type="success"
                      style="width: 96%; margin-top: 2%"
                      transition="scale-transition"
                      >{{ this.CompanySuccess }}</v-alert
                    >
                  </v-col>
                  <v-col class="ma-0 pa-0" cols="12">
                    <v-btn
                      type="submit"
                      variant="flat"
                      color="red"
                      style="height: 4vh; margin-top: 2%"
                      @click="addCompany()"
                      >Create Company</v-btn
                    >
                  </v-col>
                </v-form>
              </v-row>
            </v-card-actions>
          </v-card>
          <v-card height="auto" style="padding: 2%; margin-top: 2%">
            <v-card-title>Generate License:</v-card-title>
            <v-card-actions>
              <v-row style="margin-left: 2%; padding: 0%">
                <v-col class="ma-0 pa-0" cols="12">
                  <v-select
                    style="margin-top: 2%"
                    density="compact"
                    label="Select Company"
                    :items="CompanyList"
                    item-title="name"
                    v-model="chosenCompany"
                  ></v-select>
                </v-col>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-btn variant="flat" color="red" @click="generateLicense()"
                    >Create License</v-btn
                  >
                </v-col>
                <v-col class="ma-0 pa-0" cols="12">
                  <v-text-field
                    style="margin-top: 3%"
                    label="License key"
                    readonly="true"
                    v-model="fulllicense"
                    single-line
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6" style="padding-right: 1%; padding-top: 0%">
          <v-data-table-virtual
            :headers="headers"
            :items="visibleLicenses"
            :items-per-page="15"
            class="elevation-1"
            height="100%"
          >
            <template v-slot:item.active="{ item }">
              <v-checkbox-btn
                @click="changeLicense(item)"
                v-model="item.active"
              ></v-checkbox-btn>
            </template>
          </v-data-table-virtual>

          <v-pagination
            v-model="currentPage"
            :length="Math.ceil(allLicenses.length / 15)"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-main>
</template>

<script>
export default {
  mounted() {
    this.getLicenses();
    this.getCompanies();
  },
  data() {
    return {
      CompanyInfo: {
        Companyname: null,
        Kvknum: null,
        Email: null,
        Phone: null,
        BtwNum: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      chosenCompany: null,
      chosenLicense: null,
      CompanySuccess: null,
      CompanyError: null,
      success: null,
      error: null,
      fulllicense: null,
      allLicenses: [],
      currentPage: 1,
      CompanyList: [
        {
          Id: null,
          btwnum: null,
          email: null,
          kvknum: null,
          name: null,
          phone: null,
        },
      ],
      headers: [
        {
          title: "License Key",
          align: "start",
          sortable: "true",
          key: "license",
        },
        { title: "Company name", key: "Company.name" },
        { title: "Active", key: "active" },
      ],
    };
  },
  computed: {
    visibleLicenses() {
      const start = (this.currentPage - 1) * 15;
      const end = start + 15;
      return this.allLicenses.slice(start, end);
    },
  },
  methods: {
    getLicenses() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get("https://api.sboss.nl:8002/licences")
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            let genitems = response.data;
            genitems.forEach((item) => {
              item.license = comp.splitStringIntoParts(item.license);
            });
            comp.allLicenses = response.data;
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    getCompanies() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      this.axios
        .get("https://api.sboss.nl:8002/companies")
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            comp.CompanyList = response.data;
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    itemProps(item) {
      console.log(item);
      return {
        title: item.license,
        subtitle: item.Company.name,
      };
    },
    changeLicense(item) {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;

      let clearlicense = item.license.replace(/-/g, "");
      console.log(item.license);
      if (item.active) {
        this.axios
          .post("https://api.sboss.nl:8002/license/disable", {
            license: clearlicense,
          })
          .then(function (response) {
            if (response.status == 200) {
              console.log(response.data);
              comp.success = response.data.License;
              comp.getLicenses();
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      } else {
        this.axios
          .post("https://api.sboss.nl:8002/license/enable", {
            license: clearlicense,
          })
          .then(function (response) {
            if (response.status == 200) {
              console.log(response.data);
              comp.success = response.data.License;
              comp.getLicenses();
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      }
    },
    generateLicense() {
      let comp = this;
      const company = this.CompanyList.find(
        (item) => item.name === this.chosenCompany
      );
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getToken;
      console.log(company);
      this.axios
        .post("https://api.sboss.nl:8002/license", {
          active: true,
          company: company.Id,
        })
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            comp.fulllicense = comp.splitStringIntoParts(response.data.license);
            comp.getLicenses();
          }
        })
        .catch((error) => {
          console.log("error = " + error);
        });
    },
    addCompany() {
      let comp = this;
      this.CompanyError = null;
      this.CompanySuccess = null;
      if (
        this.CompanyInfo.Companyname == null ||
        this.CompanyInfo.Kvknum == null ||
        this.CompanyInfo.Email == null ||
        this.CompanyInfo.Phone == null ||
        this.CompanyInfo.BtwNum == null
      ) {
        comp.CompanyError = "Fill in all fields please!";
        return;
      }
      this.axios
        .post("https://api.sboss.nl:8002/company", {
          name: this.CompanyInfo.Companyname,
          kvknum: this.CompanyInfo.Kvknum,
          email: this.CompanyInfo.Email,
          phone: this.CompanyInfo.Phone,
          btwnum: this.CompanyInfo.BtwNum,
        })
        .then(function (response) {
          if (response.status == 200) {
            console.log(response.data);
            comp.CompanySuccess = "Succes!";
            comp.getCompanies();
          }
        })
        .catch((error) => {
          console.log("error = " + error);
          comp.CompanyError = "error communicating with the server!";
        });
    },
    splitStringIntoParts(inputString) {
      if (inputString.length !== 20) {
        return "Ongeldige invoer. De invoerstring moet precies 20 tekens bevatten.";
      }

      const part1 = inputString.substring(0, 5);
      const part2 = inputString.substring(5, 10);
      const part3 = inputString.substring(10, 15);
      const part4 = inputString.substring(15);

      return `${part1}-${part2}-${part3}-${part4}`;
    },
  },
};
</script>
